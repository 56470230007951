<template>
   <div class="page_section">
      <MobileTitle :title="banner.title"/>
      <Banner :content="banner"/>

      <TrafficInformation/>

      <Footer/>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import Footer from "../_common/Footer";
   import MobileTitle from "../_common/MobileTitle";
   import Banner from "../_content_elements/Banner";
   import TrafficInformation from "../_content_elements/TrafficInformation";

   export default {
      name: "TrafficNews",
      components: {
         Footer,
         Banner,
         MobileTitle,
         TrafficInformation
      },
      data() {
         return {
            banner: {
               title: 'Aktuelle Meldungen',
               image: '/abellio_banner.jpg',
               smallBanner: false
            },
         }
      },
      computed: {
         ...mapState([
            'page',
            'settings'
         ])
      }
      // ,
      // created() {
      //    this.$store.dispatch('page/getBySlug', 'single-aktuelle-meldung').then(() => {
      //       this.pageLoaded = true;
      //    }, (error) => {
      //       if (error.response.status === 404) {
      //          this.$router.replace({
      //             name: '404', params: {
      //                locale: this.$route.params.locale ? this.$route.params.locale : null
      //             }
      //          });
      //       }
      //    });
      // },
      // beforeDestroy() {
      //    this.$store.commit('page/setBySlug', {});
      // }
   }
</script>
